// theme.ts

import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  styles: {
    global: {
      'html, body': {
        margin: '0',
        padding: '0',
        fontFamily: '8xl Academy Engraved, sans-serif',
        backgroundColor: 'purple.300',
        color: 'white',
        height: '100%',
      },
    },
  },
});

export default theme;
