// App.tsx

import React from 'react';
import { ChakraProvider, Box, Image, Center, Text } from '@chakra-ui/react';
import theme from './themes/theme';

const App: React.FC = () => {
  return (
    <ChakraProvider theme={theme}>
      <Box minH="100vh" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
        <Text fontSize="8xl" fontFamily="Academy Engraved LET" marginBottom="1rem">
          Hello World
        </Text>
      </Box>
    </ChakraProvider>
  );
}

export default App;
